@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes floating {
  0% {
    transform: translateX(-50%) translateY(0%);
  }
  50% {
    transform: translateX(-50%) translateY(8%);
  }
  100% {
    transform: translateX(-50%) translateY(0%);
  }
}
@keyframes floatingY {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(8%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes cliprotate {
  0% {
    transform: rotate(0deg);
    border-top-color: white;
  }
  25% {
    transform: rotate(90deg);
    border-top-color: white;
    border-right-color: white;
  }
  75% {
    transform: rotate(180deg);
    border-top-color: white;
    border-right-color: white;
    border-bottom-color: white;
  }
  100% {
    transform: rotate(360deg);
    border-top-color: white;
    border-right-color: white;
    border-bottom-color: white;
    border-left-color: white;
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes orbit {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
@keyframes orbit-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes orbit-reverse {
  0% {
    transform: rotateZ(-3600deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes bgScrollLeft {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 100% -230px;
  }
}
@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 white;
    box-shadow: 0 0 0 0 white;
  }
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes action-bounce {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(0, 4px);
  }
  50% {
    transform: translate(0, -15px);
  }
  90% {
    transform: translate(0, 4px);
  }
}
@keyframes action-jelly {
  0%,
  100% {
    transform: scaleX(1);
  }
  20% {
    transform: scaleX(0.9);
  }
  50% {
    transform: scaleX(1.25);
  }
  85% {
    transform: scaleX(0.8);
  }
}
@keyframes jello {
  11.1% {
    transform: none;
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    transform: none;
  }
}
@keyframes jumping {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  70% {
    -webkit-transform: scale(1.02, 0.9) translateY(10px);
    transform: scale(1.02, 0.9) translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes jumping-rotate {
  0% {
    -webkit-transform: translateY(-40px) rotate(45deg);
    transform: translateY(-40px) rotate(45deg);
  }
  70% {
    -webkit-transform: scale(1.02, 0.9) translateY(10px) rotate(45deg);
    transform: scale(1.02, 0.9) translateY(10px) rotate(45deg);
  }
  100% {
    -webkit-transform: translateY(-40px) rotate(45deg);
    transform: translateY(-40px) rotate(45deg);
  }
}
@keyframes boxWithShadow {
  0% {
    width: 80%;
    opacity: 1;
  }
  50% {
    width: 50%;
    opacity: 0.5;
  }
  to {
    width: 80%;
    opacity: 1;
  }
}
@keyframes bounceDown-on {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes bounceRight-on {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-8px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes bounceDown {
  0% {
    transform: translateY(0) scale(0.88);
  }
  50% {
    transform: translateY(-5rem) scale(0.88);
  }
  to {
    transform: translateY(0) scale(0.88);
  }
}
@keyframes shadowRegulator {
  0% {
    width: 80%;
    opacity: 1;
  }
  50% {
    width: 50%;
    opacity: 0.5;
  }
  to {
    width: 80%;
    opacity: 1;
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes upDown2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.5rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes upDown-totate {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-1rem);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes scale1 {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
@keyframes roate1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes roate1rivers {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes scaleImage {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes backForward {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes leftTop {
  from {
    opacity: 0;
    transform: rotate(360deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes leftBottom {
  from {
    opacity: 0;
    transform: rotate(-360deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes rightTop {
  from {
    opacity: 0;
    transform: rotate(-100deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes rightBottom {
  from {
    opacity: 0;
    transform: rotate(100deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes blurAnimate {
  0% {
    filter: blur(10px);
  }
  50% {
    filter: blur(5px);
  }
  100% {
    filter: blur(10px);
  }
}
@keyframes rightLeft {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.manrope {
  font-family: "Manrope", sans-serif;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 50px;
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 35px;
  }
}

h3 {
  font-size: 40px;
}
@media screen and (max-width: 768px) {
  h3 {
    font-size: 25px;
  }
}

h4 {
  font-size: 30px;
}
@media screen and (max-width: 768px) {
  h4 {
    font-size: 20px;
  }
}

h5 {
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  h6 {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 900;
}

.thin {
  font-weight: 100 !important;
}

.extra-light {
  font-weight: 200 !important;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.extra-bold {
  font-weight: 800 !important;
}

.black {
  font-weight: 900 !important;
}

.normal {
  font-weight: normal !important;
}

.uppercase__font {
  text-transform: uppercase;
}

.capitalize__font {
  text-transform: capitalize;
}

.justify {
  text-align: justify;
}

.underline {
  text-decoration: underline;
}

@font-face {
  font-family: circular;
  src: url("../fonts/CircularStd-Book.ttf");
}
.circular {
  font-family: circular;
}

body {
  font-family: "JustSansFont", sans-serif;
  color: #2e2f3a;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.03em;
  line-height: 1.7em;
  background-color: #fbfafe;
}

.decoration {
  text-decoration: underline;
}
.decoration:hover {
  text-decoration: underline;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.text__decoration {
  text-decoration: underline;
}

img {
  max-width: 100%;
}

section {
  padding: 40px 0;
}

.container {
  max-width: 1300px;
}
@media screen and (max-width: 1200px) {
  .container {
    max-width: 92%;
  }
}
@media screen and (max-width: 1024px) {
  .container.none-container-1024 {
    max-width: 100%;
    padding: 0;
  }
}

.divider {
  height: 30px;
}
.divider__xs {
  height: 10px;
}
.divider__sm {
  height: 20px;
}
.divider__md {
  height: 40px;
}
.divider__lg {
  height: 60px;
}
.divider__xlg {
  height: 80px;
}
.divider__xxlg {
  height: 100px;
}

.modal-content {
  border: 0;
  padding: 40px;
  border-radius: 10px;
}
.modal-content.send-mail {
  padding: 20px;
}

.tooltip {
  border-radius: 10px;
}
.tooltip > .tooltip-inner {
  padding: 8px 15px;
  font-weight: 500;
  font-family: "Lato";
  color: #ffffff !important;
  letter-spacing: 0.01em;
  font-size: 13px;
  background-color: #2e2f3a !important;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #2e2f3a !important;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #2e2f3a !important;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #2e2f3a !important;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #2e2f3a !important;
}

.pointer {
  cursor: pointer;
}

.pointer__default {
  cursor: default;
}

.no__outline {
  outline-style: none !important;
}
.no__outline:focus {
  outline-style: none !important;
}

.modal {
  background-color: rgba(9, 9, 9, 0.12) !important;
  backdrop-filter: blur(10px);
}

.width__full {
  width: 100%;
}

.max-width-1140 {
  max-width: 1140px;
}
@media screen and (max-width: 576px) {
  .max-width-1140 {
    max-width: 92%;
  }
}

.object-fit-content {
  object-fit: contain !important;
}

.min-height-100 {
  min-height: 100vh;
}

.max-height-100 {
  max-height: 100vh;
}

.height__100 {
  height: 100vh;
}

.no__maxwid {
  max-width: 100% !important;
}

.fit-content {
  max-width: fit-content !important;
}

@media screen and (max-width: 576px) {
  .no__xs-fit {
    max-width: inherit !important;
  }
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.ul__nostyle {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media screen and (min-width: 1440px) {
  .max-1440-up {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.lh-2 {
  line-height: 2 !important;
}

.mt-min-10 {
  margin-top: -10px;
}

.img-content {
  object-fit: contain;
}

.img-cover {
  object-fit: cover;
}

.rounded__50 {
  border-radius: 50px !important;
}
.rounded__12 {
  border-radius: 12px !important;
}

.mt-min-5 {
  margin-top: -5px;
}

.mb-min-5 {
  margin-bottom: -5px;
}

.bg__white {
  background-color: #ffffff !important;
}

.bg__black {
  background-color: #2e2f3a !important;
}

.bg__purple {
  background-color: #8682e3 !important;
}

.bg__blue {
  background-color: #679ae5 !important;
}

.bg__blue-2 {
  background-color: #f0f5ff !important;
}

.bg__transparent {
  background-color: transparent !important;
}

.bg__gray-1 {
  background-color: #626475 !important;
}

.bg__gray-2 {
  background-color: #fbfafe !important;
}

.opacity__1 {
  opacity: 0.1;
  transition: 0.5s;
}
.opacity__1.hover:hover {
  opacity: 1;
}

.opacity__2 {
  opacity: 0.2;
  transition: 0.5s;
}
.opacity__2.hover:hover {
  opacity: 1;
}

.opacity__3 {
  opacity: 0.3;
  transition: 0.5s;
}
.opacity__3.hover:hover {
  opacity: 1;
}

.opacity__4 {
  opacity: 0.4;
  transition: 0.5s;
}
.opacity__4.hover:hover {
  opacity: 1;
}

.opacity__5 {
  opacity: 0.5;
  transition: 0.5s;
}
.opacity__5.hover:hover {
  opacity: 1;
}

.opacity__6 {
  opacity: 0.6;
  transition: 0.5s;
}
.opacity__6.hover:hover {
  opacity: 1;
}

.opacity__7 {
  opacity: 0.7;
  transition: 0.5s;
}
.opacity__7.hover:hover {
  opacity: 1;
}

.opacity__8 {
  opacity: 0.8;
  transition: 0.5s;
}
.opacity__8.hover:hover {
  opacity: 1;
}

.opacity__9 {
  opacity: 0.9;
  transition: 0.5s;
}
.opacity__9.hover:hover {
  opacity: 1;
}

.opacity__10 {
  opacity: 1;
  transition: 0.5s;
}
.opacity__10.hover:hover {
  opacity: 1;
}

.border__radius--0 {
  border-radius: 0px !important;
}

.border__radius--1 {
  border-radius: 1px !important;
}

.border__radius--2 {
  border-radius: 2px !important;
}

.border__radius--3 {
  border-radius: 3px !important;
}

.border__radius--4 {
  border-radius: 4px !important;
}

.border__radius--5 {
  border-radius: 5px !important;
}

.border__radius--6 {
  border-radius: 6px !important;
}

.border__radius--7 {
  border-radius: 7px !important;
}

.border__radius--8 {
  border-radius: 8px !important;
}

.border__radius--9 {
  border-radius: 9px !important;
}

.border__radius--10 {
  border-radius: 10px !important;
}

.border__radius--11 {
  border-radius: 11px !important;
}

.border__radius--12 {
  border-radius: 12px !important;
}

.border__radius--13 {
  border-radius: 13px !important;
}

.border__radius--14 {
  border-radius: 14px !important;
}

.border__radius--15 {
  border-radius: 15px !important;
}

.border__radius--16 {
  border-radius: 16px !important;
}

.border__radius--17 {
  border-radius: 17px !important;
}

.border__radius--18 {
  border-radius: 18px !important;
}

.border__radius--19 {
  border-radius: 19px !important;
}

.border__radius--20 {
  border-radius: 20px !important;
}

.border__radius--21 {
  border-radius: 21px !important;
}

.border__radius--22 {
  border-radius: 22px !important;
}

.border__radius--23 {
  border-radius: 23px !important;
}

.border__radius--24 {
  border-radius: 24px !important;
}

.border__radius--25 {
  border-radius: 25px !important;
}

.border__radius--26 {
  border-radius: 26px !important;
}

.border__radius--27 {
  border-radius: 27px !important;
}

.border__radius--28 {
  border-radius: 28px !important;
}

.border__radius--29 {
  border-radius: 29px !important;
}

.border__radius--30 {
  border-radius: 30px !important;
}

.border__radius--31 {
  border-radius: 31px !important;
}

.border__radius--32 {
  border-radius: 32px !important;
}

.border__radius--33 {
  border-radius: 33px !important;
}

.border__radius--34 {
  border-radius: 34px !important;
}

.border__radius--35 {
  border-radius: 35px !important;
}

.border__radius--36 {
  border-radius: 36px !important;
}

.border__radius--37 {
  border-radius: 37px !important;
}

.border__radius--38 {
  border-radius: 38px !important;
}

.border__radius--39 {
  border-radius: 39px !important;
}

.border__radius--40 {
  border-radius: 40px !important;
}

.border__radius--41 {
  border-radius: 41px !important;
}

.border__radius--42 {
  border-radius: 42px !important;
}

.border__radius--43 {
  border-radius: 43px !important;
}

.border__radius--44 {
  border-radius: 44px !important;
}

.border__radius--45 {
  border-radius: 45px !important;
}

.border__radius--46 {
  border-radius: 46px !important;
}

.border__radius--47 {
  border-radius: 47px !important;
}

.border__radius--48 {
  border-radius: 48px !important;
}

.border__radius--49 {
  border-radius: 49px !important;
}

.border__radius--50 {
  border-radius: 50px !important;
}

.color__white {
  color: #ffffff !important;
}

.color__black {
  color: #2e2f3a !important;
}

.color__purple {
  color: #8682e3 !important;
}

.color__blue {
  color: #679ae5 !important;
}

.color__blue-2 {
  color: #f0f5ff !important;
}

.color__transparent {
  color: transparent !important;
}

.color__gray-1 {
  color: #626475 !important;
}

.color__gray-2 {
  color: #fbfafe !important;
}

.fw__1 {
  font-weight: 100 !important;
}

.fw__2 {
  font-weight: 200 !important;
}

.fw__3 {
  font-weight: 300 !important;
}

.fw__4 {
  font-weight: 400 !important;
}

.fw__5 {
  font-weight: 500 !important;
}

.fw__6 {
  font-weight: 600 !important;
}

.fw__7 {
  font-weight: 700 !important;
}

.fw__8 {
  font-weight: 800 !important;
}

.lh__1 {
  line-height: 1.1 !important;
}

.lh__2 {
  line-height: 1.2 !important;
}

.lh__3 {
  line-height: 1.3 !important;
}

.lh__4 {
  line-height: 1.4 !important;
}

.lh__5 {
  line-height: 1.5 !important;
}

.lh__6 {
  line-height: 1.6 !important;
}

.lh__7 {
  line-height: 1.7 !important;
}

.lh__8 {
  line-height: 1.8 !important;
}

.lh__9 {
  line-height: 1.9 !important;
}

.ls__1 {
  letter-spacing: 0.2em !important;
}

.ls__2 {
  letter-spacing: 0.3em !important;
}

.ls__3 {
  letter-spacing: 0.4em !important;
}

.ls__4 {
  letter-spacing: 0.5em !important;
}

.ls__5 {
  letter-spacing: 0.6em !important;
}

.ls__6 {
  letter-spacing: 0.7em !important;
}

.ls__7 {
  letter-spacing: 0.8em !important;
}

.ls__8 {
  letter-spacing: 0.9em !important;
}

.ls__9 {
  letter-spacing: 1em !important;
}

.clamp__1 {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp__2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.font__size--6 {
  font-size: 6px !important;
}

.font__size--7 {
  font-size: 7px !important;
}

.font__size--8 {
  font-size: 8px !important;
}

.font__size--9 {
  font-size: 9px !important;
}

.font__size--10 {
  font-size: 10px !important;
}

.font__size--11 {
  font-size: 11px !important;
}

.font__size--12 {
  font-size: 12px !important;
}

.font__size--13 {
  font-size: 13px !important;
}

.font__size--14 {
  font-size: 14px !important;
}

.font__size--15 {
  font-size: 15px !important;
}

.font__size--16 {
  font-size: 16px !important;
}

.font__size--17 {
  font-size: 17px !important;
}

.font__size--18 {
  font-size: 18px !important;
}

.font__size--19 {
  font-size: 19px !important;
}

.font__size--20 {
  font-size: 20px !important;
}

.font__size--21 {
  font-size: 21px !important;
}

.font__size--22 {
  font-size: 22px !important;
}

.font__size--23 {
  font-size: 23px !important;
}

.font__size--24 {
  font-size: 24px !important;
}

.font__size--25 {
  font-size: 25px !important;
}

.font__size--26 {
  font-size: 26px !important;
}

.font__size--27 {
  font-size: 27px !important;
}

.font__size--28 {
  font-size: 28px !important;
}

.font__size--29 {
  font-size: 29px !important;
}

.font__size--30 {
  font-size: 30px !important;
}

.font__size--31 {
  font-size: 31px !important;
}

.font__size--32 {
  font-size: 32px !important;
}

.font__size--33 {
  font-size: 33px !important;
}

.font__size--34 {
  font-size: 34px !important;
}

.font__size--35 {
  font-size: 35px !important;
}

.font__size--36 {
  font-size: 36px !important;
}

.font__size--37 {
  font-size: 37px !important;
}

.font__size--38 {
  font-size: 38px !important;
}

.font__size--39 {
  font-size: 39px !important;
}

.font__size--40 {
  font-size: 40px !important;
}

.font__size--41 {
  font-size: 41px !important;
}

.font__size--42 {
  font-size: 42px !important;
}

.font__size--43 {
  font-size: 43px !important;
}

.font__size--44 {
  font-size: 44px !important;
}

.font__size--45 {
  font-size: 45px !important;
}

.font__size--46 {
  font-size: 46px !important;
}

.font__size--47 {
  font-size: 47px !important;
}

.font__size--48 {
  font-size: 48px !important;
}

.font__size--49 {
  font-size: 49px !important;
}

.font__size--50 {
  font-size: 50px !important;
}

.font__size--51 {
  font-size: 51px !important;
}

.font__size--52 {
  font-size: 52px !important;
}

.font__size--53 {
  font-size: 53px !important;
}

.font__size--54 {
  font-size: 54px !important;
}

.font__size--55 {
  font-size: 55px !important;
}

.font__size--56 {
  font-size: 56px !important;
}

.font__size--57 {
  font-size: 57px !important;
}

.font__size--58 {
  font-size: 58px !important;
}

.font__size--59 {
  font-size: 59px !important;
}

.font__size--60 {
  font-size: 60px !important;
}

.font__size--61 {
  font-size: 61px !important;
}

.font__size--62 {
  font-size: 62px !important;
}

.font__size--63 {
  font-size: 63px !important;
}

.font__size--64 {
  font-size: 64px !important;
}

.font__size--65 {
  font-size: 65px !important;
}

.font__size--66 {
  font-size: 66px !important;
}

.font__size--67 {
  font-size: 67px !important;
}

.font__size--68 {
  font-size: 68px !important;
}

.font__size--69 {
  font-size: 69px !important;
}

.font__size--70 {
  font-size: 70px !important;
}

.font__size--71 {
  font-size: 71px !important;
}

.font__size--72 {
  font-size: 72px !important;
}

.font__size--73 {
  font-size: 73px !important;
}

.font__size--74 {
  font-size: 74px !important;
}

.font__size--75 {
  font-size: 75px !important;
}

.font__size--76 {
  font-size: 76px !important;
}

.font__size--77 {
  font-size: 77px !important;
}

.font__size--78 {
  font-size: 78px !important;
}

.font__size--79 {
  font-size: 79px !important;
}

.font__size--80 {
  font-size: 80px !important;
}

.font__size--81 {
  font-size: 81px !important;
}

.font__size--82 {
  font-size: 82px !important;
}

.font__size--83 {
  font-size: 83px !important;
}

.font__size--84 {
  font-size: 84px !important;
}

.font__size--85 {
  font-size: 85px !important;
}

.font__size--86 {
  font-size: 86px !important;
}

.font__size--87 {
  font-size: 87px !important;
}

.font__size--88 {
  font-size: 88px !important;
}

.font__size--89 {
  font-size: 89px !important;
}

.font__size--90 {
  font-size: 90px !important;
}

.font__size--91 {
  font-size: 91px !important;
}

.font__size--92 {
  font-size: 92px !important;
}

.font__size--93 {
  font-size: 93px !important;
}

.font__size--94 {
  font-size: 94px !important;
}

.font__size--95 {
  font-size: 95px !important;
}

.font__size--96 {
  font-size: 96px !important;
}

.font__size--97 {
  font-size: 97px !important;
}

.font__size--98 {
  font-size: 98px !important;
}

.font__size--99 {
  font-size: 99px !important;
}

.font__size--100 {
  font-size: 100px !important;
}

.font__size--101 {
  font-size: 101px !important;
}

.font__size--102 {
  font-size: 102px !important;
}

.font__size--103 {
  font-size: 103px !important;
}

.font__size--104 {
  font-size: 104px !important;
}

.font__size--105 {
  font-size: 105px !important;
}

.font__size--106 {
  font-size: 106px !important;
}

.font__size--107 {
  font-size: 107px !important;
}

.font__size--108 {
  font-size: 108px !important;
}

.font__size--109 {
  font-size: 109px !important;
}

.font__size--110 {
  font-size: 110px !important;
}

.font__size--111 {
  font-size: 111px !important;
}

.font__size--112 {
  font-size: 112px !important;
}

.font__size--113 {
  font-size: 113px !important;
}

.font__size--114 {
  font-size: 114px !important;
}

.font__size--115 {
  font-size: 115px !important;
}

.font__size--116 {
  font-size: 116px !important;
}

.font__size--117 {
  font-size: 117px !important;
}

.font__size--118 {
  font-size: 118px !important;
}

.font__size--119 {
  font-size: 119px !important;
}

.font__size--120 {
  font-size: 120px !important;
}

.font__size--121 {
  font-size: 121px !important;
}

.font__size--122 {
  font-size: 122px !important;
}

.font__size--123 {
  font-size: 123px !important;
}

.font__size--124 {
  font-size: 124px !important;
}

.font__size--125 {
  font-size: 125px !important;
}

.font__size--126 {
  font-size: 126px !important;
}

.font__size--127 {
  font-size: 127px !important;
}

.font__size--128 {
  font-size: 128px !important;
}

.font__size--129 {
  font-size: 129px !important;
}

.font__size--130 {
  font-size: 130px !important;
}

.font__size--131 {
  font-size: 131px !important;
}

.font__size--132 {
  font-size: 132px !important;
}

.font__size--133 {
  font-size: 133px !important;
}

.font__size--134 {
  font-size: 134px !important;
}

.font__size--135 {
  font-size: 135px !important;
}

.font__size--136 {
  font-size: 136px !important;
}

.font__size--137 {
  font-size: 137px !important;
}

.font__size--138 {
  font-size: 138px !important;
}

.font__size--139 {
  font-size: 139px !important;
}

.font__size--140 {
  font-size: 140px !important;
}

.font__size--141 {
  font-size: 141px !important;
}

.font__size--142 {
  font-size: 142px !important;
}

.font__size--143 {
  font-size: 143px !important;
}

.font__size--144 {
  font-size: 144px !important;
}

.font__size--145 {
  font-size: 145px !important;
}

.font__size--146 {
  font-size: 146px !important;
}

.font__size--147 {
  font-size: 147px !important;
}

.font__size--148 {
  font-size: 148px !important;
}

.font__size--149 {
  font-size: 149px !important;
}

.font__size--150 {
  font-size: 150px !important;
}

.font__size--151 {
  font-size: 151px !important;
}

.font__size--152 {
  font-size: 152px !important;
}

.font__size--153 {
  font-size: 153px !important;
}

.font__size--154 {
  font-size: 154px !important;
}

.font__size--155 {
  font-size: 155px !important;
}

.font__size--156 {
  font-size: 156px !important;
}

.font__size--157 {
  font-size: 157px !important;
}

.font__size--158 {
  font-size: 158px !important;
}

.font__size--159 {
  font-size: 159px !important;
}

.font__size--160 {
  font-size: 160px !important;
}

.font__size--161 {
  font-size: 161px !important;
}

.font__size--162 {
  font-size: 162px !important;
}

.font__size--163 {
  font-size: 163px !important;
}

.font__size--164 {
  font-size: 164px !important;
}

.font__size--165 {
  font-size: 165px !important;
}

.font__size--166 {
  font-size: 166px !important;
}

.font__size--167 {
  font-size: 167px !important;
}

.font__size--168 {
  font-size: 168px !important;
}

.font__size--169 {
  font-size: 169px !important;
}

.font__size--170 {
  font-size: 170px !important;
}

.font__size--171 {
  font-size: 171px !important;
}

.font__size--172 {
  font-size: 172px !important;
}

.font__size--173 {
  font-size: 173px !important;
}

.font__size--174 {
  font-size: 174px !important;
}

.font__size--175 {
  font-size: 175px !important;
}

.font__size--176 {
  font-size: 176px !important;
}

.font__size--177 {
  font-size: 177px !important;
}

.font__size--178 {
  font-size: 178px !important;
}

.font__size--179 {
  font-size: 179px !important;
}

.font__size--180 {
  font-size: 180px !important;
}

.font__size--181 {
  font-size: 181px !important;
}

.font__size--182 {
  font-size: 182px !important;
}

.font__size--183 {
  font-size: 183px !important;
}

.font__size--184 {
  font-size: 184px !important;
}

.font__size--185 {
  font-size: 185px !important;
}

.font__size--186 {
  font-size: 186px !important;
}

.font__size--187 {
  font-size: 187px !important;
}

.font__size--188 {
  font-size: 188px !important;
}

.font__size--189 {
  font-size: 189px !important;
}

.font__size--190 {
  font-size: 190px !important;
}

.font__size--191 {
  font-size: 191px !important;
}

.font__size--192 {
  font-size: 192px !important;
}

.font__size--193 {
  font-size: 193px !important;
}

.font__size--194 {
  font-size: 194px !important;
}

.font__size--195 {
  font-size: 195px !important;
}

.font__size--196 {
  font-size: 196px !important;
}

.font__size--197 {
  font-size: 197px !important;
}

.font__size--198 {
  font-size: 198px !important;
}

.font__size--199 {
  font-size: 199px !important;
}

.font__size--200 {
  font-size: 200px !important;
}

.manrope {
  font-family: "Manrope", sans-serif;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 50px;
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 35px;
  }
}

h3 {
  font-size: 40px;
}
@media screen and (max-width: 768px) {
  h3 {
    font-size: 25px;
  }
}

h4 {
  font-size: 30px;
}
@media screen and (max-width: 768px) {
  h4 {
    font-size: 20px;
  }
}

h5 {
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  h6 {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 900;
}

.thin {
  font-weight: 100 !important;
}

.extra-light {
  font-weight: 200 !important;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.extra-bold {
  font-weight: 800 !important;
}

.black {
  font-weight: 900 !important;
}

.normal {
  font-weight: normal !important;
}

.uppercase__font {
  text-transform: uppercase;
}

.capitalize__font {
  text-transform: capitalize;
}

.justify {
  text-align: justify;
}

.underline {
  text-decoration: underline;
}

@font-face {
  font-family: circular;
  src: url("../fonts/CircularStd-Book.ttf");
}
.circular {
  font-family: circular;
}

.btn {
  font-weight: 700;
  padding: 17px 45px;
  border-radius: 5px;
}
.btn:focus {
  box-shadow: none;
}
.btn__white {
  background-color: #ffffff;
}
.btn__white.shadow {
  box-shadow: none !important;
}
.btn__white.shadow:hover {
  box-shadow: 0 15px 30px rgba(255, 255, 255, 0.5) !important;
}
.btn__outlined--white {
  border: 2px solid #ffffff;
  color: #ffffff;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--white.b-1 {
  border: 1px solid #ffffff;
}
.btn__outlined--white.h__black:hover {
  color: #2e2f3a !important;
}
.btn__outlined--white.h__white:hover {
  color: #ffffff !important;
}
.btn__outlined--white .svg__red path {
  transition: 0.5s;
}
.btn__outlined--white.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--white.shadow {
  box-shadow: none !important;
}
.btn__outlined--white.shadow:hover {
  box-shadow: 0 15px 30px rgba(255, 255, 255, 0.5) !important;
}
.btn__outlined--white:hover {
  opacity: 1;
  background-color: #ffffff;
  color: white !important;
}
.btn__outlined--white:hover.darkblue {
  color: !important;
}
.btn__outlined--white:hover.pink {
  color: !important;
}
.btn__outlined--white.nohover:hover {
  border: 2px solid #ffffff;
  color: #ffffff;
  opacity: 0.3;
  background-color: transparent;
}
.btn__black {
  color: #ffffff;
  background-color: #2e2f3a;
}
.btn__black.shadow {
  box-shadow: none !important;
}
.btn__black.shadow:hover {
  box-shadow: 0 15px 30px rgba(46, 47, 58, 0.5) !important;
}
.btn__outlined--black {
  border: 2px solid #2e2f3a;
  color: #2e2f3a;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--black.b-1 {
  border: 1px solid #2e2f3a;
}
.btn__outlined--black.h__black:hover {
  color: #2e2f3a !important;
}
.btn__outlined--black.h__white:hover {
  color: #ffffff !important;
}
.btn__outlined--black .svg__red path {
  transition: 0.5s;
}
.btn__outlined--black.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--black.shadow {
  box-shadow: none !important;
}
.btn__outlined--black.shadow:hover {
  box-shadow: 0 15px 30px rgba(46, 47, 58, 0.5) !important;
}
.btn__outlined--black:hover {
  opacity: 1;
  background-color: #2e2f3a;
  color: white !important;
}
.btn__outlined--black:hover.darkblue {
  color: !important;
}
.btn__outlined--black:hover.pink {
  color: !important;
}
.btn__outlined--black.nohover:hover {
  border: 2px solid #2e2f3a;
  color: #2e2f3a;
  opacity: 0.3;
  background-color: transparent;
}
.btn__purple {
  color: #ffffff;
  background-color: #8682e3;
}
.btn__purple.shadow {
  box-shadow: none !important;
}
.btn__purple.shadow:hover {
  box-shadow: 0 15px 30px rgba(134, 130, 227, 0.5) !important;
}
.btn__outlined--purple {
  border: 2px solid #8682e3;
  color: #8682e3;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--purple.b-1 {
  border: 1px solid #8682e3;
}
.btn__outlined--purple.h__black:hover {
  color: #2e2f3a !important;
}
.btn__outlined--purple.h__white:hover {
  color: #ffffff !important;
}
.btn__outlined--purple .svg__red path {
  transition: 0.5s;
}
.btn__outlined--purple.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--purple.shadow {
  box-shadow: none !important;
}
.btn__outlined--purple.shadow:hover {
  box-shadow: 0 15px 30px rgba(134, 130, 227, 0.5) !important;
}
.btn__outlined--purple:hover {
  opacity: 1;
  background-color: #8682e3;
  color: white !important;
}
.btn__outlined--purple:hover.darkblue {
  color: !important;
}
.btn__outlined--purple:hover.pink {
  color: !important;
}
.btn__outlined--purple.nohover:hover {
  border: 2px solid #8682e3;
  color: #8682e3;
  opacity: 0.3;
  background-color: transparent;
}
.btn__blue {
  color: #ffffff;
  background-color: #679ae5;
}
.btn__blue.shadow {
  box-shadow: none !important;
}
.btn__blue.shadow:hover {
  box-shadow: 0 15px 30px rgba(103, 154, 229, 0.5) !important;
}
.btn__outlined--blue {
  border: 2px solid #679ae5;
  color: #679ae5;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--blue.b-1 {
  border: 1px solid #679ae5;
}
.btn__outlined--blue.h__black:hover {
  color: #2e2f3a !important;
}
.btn__outlined--blue.h__white:hover {
  color: #ffffff !important;
}
.btn__outlined--blue .svg__red path {
  transition: 0.5s;
}
.btn__outlined--blue.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--blue.shadow {
  box-shadow: none !important;
}
.btn__outlined--blue.shadow:hover {
  box-shadow: 0 15px 30px rgba(103, 154, 229, 0.5) !important;
}
.btn__outlined--blue:hover {
  opacity: 1;
  background-color: #679ae5;
  color: white !important;
}
.btn__outlined--blue:hover.darkblue {
  color: !important;
}
.btn__outlined--blue:hover.pink {
  color: !important;
}
.btn__outlined--blue.nohover:hover {
  border: 2px solid #679ae5;
  color: #679ae5;
  opacity: 0.3;
  background-color: transparent;
}
.btn__blue-2 {
  background-color: #f0f5ff;
}
.btn__blue-2.shadow {
  box-shadow: none !important;
}
.btn__blue-2.shadow:hover {
  box-shadow: 0 15px 30px rgba(240, 245, 255, 0.5) !important;
}
.btn__outlined--blue-2 {
  border: 2px solid #f0f5ff;
  color: #f0f5ff;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--blue-2.b-1 {
  border: 1px solid #f0f5ff;
}
.btn__outlined--blue-2.h__black:hover {
  color: #2e2f3a !important;
}
.btn__outlined--blue-2.h__white:hover {
  color: #ffffff !important;
}
.btn__outlined--blue-2 .svg__red path {
  transition: 0.5s;
}
.btn__outlined--blue-2.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--blue-2.shadow {
  box-shadow: none !important;
}
.btn__outlined--blue-2.shadow:hover {
  box-shadow: 0 15px 30px rgba(240, 245, 255, 0.5) !important;
}
.btn__outlined--blue-2:hover {
  opacity: 1;
  background-color: #f0f5ff;
  color: white !important;
}
.btn__outlined--blue-2:hover.darkblue {
  color: !important;
}
.btn__outlined--blue-2:hover.pink {
  color: !important;
}
.btn__outlined--blue-2.nohover:hover {
  border: 2px solid #f0f5ff;
  color: #f0f5ff;
  opacity: 0.3;
  background-color: transparent;
}
.btn__transparent {
  color: #ffffff;
  background-color: transparent;
}
.btn__transparent.shadow {
  box-shadow: none !important;
}
.btn__transparent.shadow:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0) !important;
}
.btn__outlined--transparent {
  border: 2px solid transparent;
  color: transparent;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--transparent.b-1 {
  border: 1px solid transparent;
}
.btn__outlined--transparent.h__black:hover {
  color: #2e2f3a !important;
}
.btn__outlined--transparent.h__white:hover {
  color: #ffffff !important;
}
.btn__outlined--transparent .svg__red path {
  transition: 0.5s;
}
.btn__outlined--transparent.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--transparent.shadow {
  box-shadow: none !important;
}
.btn__outlined--transparent.shadow:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0) !important;
}
.btn__outlined--transparent:hover {
  opacity: 1;
  background-color: transparent;
  color: white !important;
}
.btn__outlined--transparent:hover.darkblue {
  color: !important;
}
.btn__outlined--transparent:hover.pink {
  color: !important;
}
.btn__outlined--transparent.nohover:hover {
  border: 2px solid transparent;
  color: transparent;
  opacity: 0.3;
  background-color: transparent;
}
.btn__gray-1 {
  color: #ffffff;
  background-color: #626475;
}
.btn__gray-1.shadow {
  box-shadow: none !important;
}
.btn__gray-1.shadow:hover {
  box-shadow: 0 15px 30px rgba(98, 100, 117, 0.5) !important;
}
.btn__outlined--gray-1 {
  border: 2px solid #626475;
  color: #626475;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--gray-1.b-1 {
  border: 1px solid #626475;
}
.btn__outlined--gray-1.h__black:hover {
  color: #2e2f3a !important;
}
.btn__outlined--gray-1.h__white:hover {
  color: #ffffff !important;
}
.btn__outlined--gray-1 .svg__red path {
  transition: 0.5s;
}
.btn__outlined--gray-1.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--gray-1.shadow {
  box-shadow: none !important;
}
.btn__outlined--gray-1.shadow:hover {
  box-shadow: 0 15px 30px rgba(98, 100, 117, 0.5) !important;
}
.btn__outlined--gray-1:hover {
  opacity: 1;
  background-color: #626475;
  color: white !important;
}
.btn__outlined--gray-1:hover.darkblue {
  color: !important;
}
.btn__outlined--gray-1:hover.pink {
  color: !important;
}
.btn__outlined--gray-1.nohover:hover {
  border: 2px solid #626475;
  color: #626475;
  opacity: 0.3;
  background-color: transparent;
}
.btn__gray-2 {
  background-color: #fbfafe;
}
.btn__gray-2.shadow {
  box-shadow: none !important;
}
.btn__gray-2.shadow:hover {
  box-shadow: 0 15px 30px rgba(251, 250, 254, 0.5) !important;
}
.btn__outlined--gray-2 {
  border: 2px solid #fbfafe;
  color: #fbfafe;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--gray-2.b-1 {
  border: 1px solid #fbfafe;
}
.btn__outlined--gray-2.h__black:hover {
  color: #2e2f3a !important;
}
.btn__outlined--gray-2.h__white:hover {
  color: #ffffff !important;
}
.btn__outlined--gray-2 .svg__red path {
  transition: 0.5s;
}
.btn__outlined--gray-2.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--gray-2.shadow {
  box-shadow: none !important;
}
.btn__outlined--gray-2.shadow:hover {
  box-shadow: 0 15px 30px rgba(251, 250, 254, 0.5) !important;
}
.btn__outlined--gray-2:hover {
  opacity: 1;
  background-color: #fbfafe;
  color: white !important;
}
.btn__outlined--gray-2:hover.darkblue {
  color: !important;
}
.btn__outlined--gray-2:hover.pink {
  color: !important;
}
.btn__outlined--gray-2.nohover:hover {
  border: 2px solid #fbfafe;
  color: #fbfafe;
  opacity: 0.3;
  background-color: transparent;
}

#a {
  padding-left: 0px;
  margin: 0;
}

#a img,
.btn-select img {
  width: 12px;
}

#a li {
  list-style: none;
  padding: 5px;
}

#a li:hover {
  background-color: #f4f3f3;
}

#a li img {
  margin: 5px;
}

#a li span,
.btn-select li span {
  margin-left: 10px;
}

/* item list */
.b {
  display: none;
  max-width: 250px;
  width: 100%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: absolute;
  z-index: 2;
  background-color: white;
}

.open {
  display: show !important;
}

.btn-select {
  margin-top: 0;
  height: 30px;
  max-width: 250px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #cfd0d7;
  background-color: #fcfcfd;
  position: relative;
}

.btn-select li {
  list-style: none;
  float: left;
  padding-bottom: 0px;
  padding: 0 5px;
}

.btn-select:hover li {
  margin-left: 0px;
}

.btn-select:focus {
  outline: none;
}

.lang-select {
  margin-left: 15px;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes floating {
  0% {
    transform: translateX(-50%) translateY(0%);
  }
  50% {
    transform: translateX(-50%) translateY(8%);
  }
  100% {
    transform: translateX(-50%) translateY(0%);
  }
}
@keyframes floatingY {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(8%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes cliprotate {
  0% {
    transform: rotate(0deg);
    border-top-color: white;
  }
  25% {
    transform: rotate(90deg);
    border-top-color: white;
    border-right-color: white;
  }
  75% {
    transform: rotate(180deg);
    border-top-color: white;
    border-right-color: white;
    border-bottom-color: white;
  }
  100% {
    transform: rotate(360deg);
    border-top-color: white;
    border-right-color: white;
    border-bottom-color: white;
    border-left-color: white;
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes orbit {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
@keyframes orbit-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes orbit-reverse {
  0% {
    transform: rotateZ(-3600deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes bgScrollLeft {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 100% -230px;
  }
}
@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 white;
    box-shadow: 0 0 0 0 white;
  }
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes action-bounce {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(0, 4px);
  }
  50% {
    transform: translate(0, -15px);
  }
  90% {
    transform: translate(0, 4px);
  }
}
@keyframes action-jelly {
  0%,
  100% {
    transform: scaleX(1);
  }
  20% {
    transform: scaleX(0.9);
  }
  50% {
    transform: scaleX(1.25);
  }
  85% {
    transform: scaleX(0.8);
  }
}
@keyframes jello {
  11.1% {
    transform: none;
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    transform: none;
  }
}
@keyframes jumping {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  70% {
    -webkit-transform: scale(1.02, 0.9) translateY(10px);
    transform: scale(1.02, 0.9) translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes jumping-rotate {
  0% {
    -webkit-transform: translateY(-40px) rotate(45deg);
    transform: translateY(-40px) rotate(45deg);
  }
  70% {
    -webkit-transform: scale(1.02, 0.9) translateY(10px) rotate(45deg);
    transform: scale(1.02, 0.9) translateY(10px) rotate(45deg);
  }
  100% {
    -webkit-transform: translateY(-40px) rotate(45deg);
    transform: translateY(-40px) rotate(45deg);
  }
}
@keyframes boxWithShadow {
  0% {
    width: 80%;
    opacity: 1;
  }
  50% {
    width: 50%;
    opacity: 0.5;
  }
  to {
    width: 80%;
    opacity: 1;
  }
}
@keyframes bounceDown-on {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes bounceRight-on {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-8px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes bounceDown {
  0% {
    transform: translateY(0) scale(0.88);
  }
  50% {
    transform: translateY(-5rem) scale(0.88);
  }
  to {
    transform: translateY(0) scale(0.88);
  }
}
@keyframes shadowRegulator {
  0% {
    width: 80%;
    opacity: 1;
  }
  50% {
    width: 50%;
    opacity: 0.5;
  }
  to {
    width: 80%;
    opacity: 1;
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes upDown2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.5rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes upDown-totate {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-1rem);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes scale1 {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
@keyframes roate1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes roate1rivers {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes scaleImage {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes backForward {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes leftTop {
  from {
    opacity: 0;
    transform: rotate(360deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes leftBottom {
  from {
    opacity: 0;
    transform: rotate(-360deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes rightTop {
  from {
    opacity: 0;
    transform: rotate(-100deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes rightBottom {
  from {
    opacity: 0;
    transform: rotate(100deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes blurAnimate {
  0% {
    filter: blur(10px);
  }
  50% {
    filter: blur(5px);
  }
  100% {
    filter: blur(10px);
  }
}
@keyframes rightLeft {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.field__input {
  border: 1px solid #e9ebed;
  background-color: #f9f9f9;
  padding: 1rem;
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 32px;
}
.field__input.min-250 {
  min-width: 250px;
}
@media screen and (max-width: 576px) {
  .field__input.w-sm-100 {
    width: 100%;
    min-width: auto;
  }
}
.field__input.search {
  width: 327px;
}
.field__input.round__16 {
  border-radius: 16px;
}
.field__input.verification {
  padding: 0;
  background-color: transparent;
  border: none;
}
.field__input.verification input {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  box-shadow: none;
  background: #fefefe;
  border: 1px solid #e9ebed;
  border-radius: 24px;
}
@media screen and (max-width: 512px) {
  .field__input.verification input {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 375px) {
  .field__input.verification input {
    width: 43px;
    height: 43px;
  }
}
.field__input.verification input:valid {
  border: 1px solid #e9ebed;
  border-radius: 24px;
  box-shadow: none;
}
.field__input.verification input:hover {
  border: 1px solid #8682e3;
  border-radius: 24px;
  box-shadow: none;
}
.field__input.verification input:focus {
  border: 1px solid #8682e3;
  border-radius: 24px;
  box-shadow: none;
}
.field__input.verification input:active {
  border: 1px solid #8682e3;
  border-radius: 24px;
  box-shadow: none;
}
.field__input input {
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.field__input input[type="date"]::-webkit-inner-spin-button,
.field__input input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.field__input input:hover {
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.field__input input:focus {
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.field__input input:active {
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.field__input.textarea {
  height: auto;
}
.field__input textarea {
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.field__input textarea:hover {
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.field__input textarea:focus {
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.field__input textarea:active {
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}

.close__wrap {
  width: 55px;
  height: 55px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -4rem;
  top: 0;
}
@media screen and (max-width: 768px) {
  .close__wrap {
    position: initial;
    margin-top: 3rem;
  }
}

@media screen and (min-width: 1440px) {
  .wrapper__content-offside {
    overflow: hidden;
    max-width: 1440px;
    margin: 0 auto;
  }
}
.wrapper__slider-team-wrap {
  margin: 0 -8px;
}
.wrapper__slider-team-wrap .slick-dots {
  bottom: -3rem;
}
.wrapper__slider-team-wrap .slick-dots li.slick-active button::before {
  background-color: #8682e3;
}
.wrapper__slider-team-wrap .slick-dots li button::before {
  content: "";
  background-color: #e6dcfc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.wrapper__slider-team-wrap .items {
  padding: 0 8px;
}
.wrapper__slider-team-wrap
  .slick-list
  .slick-track
  .slick-current
  .items
  .wrapper__team-user.left {
  transform: rotate(-2.29deg);
}
.wrapper__slider-team-wrap
  .slick-list
  .slick-track
  .slick-current
  .items
  .wrapper__team-user.right {
  transform: rotate(2.29deg);
}
.wrapper__team-user {
  width: 100%;
  height: 440px;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.5s;
}
.wrapper__team-user.left {
  transform: rotate(-0.12deg);
}
.wrapper__team-user.right {
  transform: rotate(0.98deg);
}
.wrapper__team-user > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wrapper__team-user .bg {
  position: absolute;
  width: 100%;
  height: 220px;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}
.wrapper__team-user .desc {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 1rem;
}
@media screen and (max-width: 576px) {
  .wrapper__offside-faq {
    overflow: auto;
  }
  .wrapper__offside-faq .auto {
    width: 700px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper__offset-table {
    overflow: auto;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .wrapper__offset-table::-webkit-scrollbar {
    width: 10px;
  }
  .wrapper__offset-table::-webkit-scrollbar-track {
    background: transparent;
  }
  .wrapper__offset-table::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .wrapper__offset-table::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  .wrapper__offset-table .auto {
    width: 912px;
  }
}
@media screen and (max-width: 576px) {
  .wrapper__offsite-character {
    padding-top: 3em;
    overflow: auto;
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .wrapper__offsite-character::-webkit-scrollbar {
    width: 10px;
  }
  .wrapper__offsite-character::-webkit-scrollbar-track {
    background: transparent;
  }
  .wrapper__offsite-character::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .wrapper__offsite-character::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  .wrapper__offsite-character .auto {
    width: 692px;
    padding-top: 3em;
  }
}
.wrapper__modal-connect {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.wrapper__modal-connect.post .modal-dialog {
  max-width: 550px !important;
}
.wrapper__modal-connect .modal-content {
  padding: 2rem 1rem;
}
.wrapper__modal-connect .modal-content .close__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #f9f9f9;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  top: -20px;
}
.wrapper__modal-connect.show {
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
}
.wrapper__modal-connect .content__modal-listing .img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 16px;
}
@media screen and (max-width: 425px) {
  .wrapper__modal-connect .content__modal-listing .img {
    width: 100px;
    height: 100px;
  }
}
.wrapper__modal-connect .content__modal-listing .wrap {
  background: #f9f9f9;
  border-radius: 12px;
  padding: 8px 12px;
}
.wrapper__checkbox-auth input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -2;
}
.wrapper__checkbox-auth input:checked ~ .circle {
  background-color: #8682e3;
}
.wrapper__checkbox-auth input:checked ~ .circle .check {
  opacity: 1;
}
.wrapper__checkbox-auth .circle {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: white;
  position: relative;
  border: 1px solid #e5e5e7;
}
.wrapper__checkbox-auth .circle .check {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  opacity: 0;
}
.wrapper__line-text .text {
  position: relative;
  z-index: 2;
  padding: 5px 24px;
  background-color: white;
}
.wrapper__line-text::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #95a5b552;
}
.wrapper__field-wrap {
  border: 1px solid #e9ebed;
  border-radius: 8px;
  padding: 16px;
}
.wrapper__field-wrap.filter {
  background: #f9f9f9;
  border: 1px solid #e9ebed;
}
.wrapper__field-wrap .wrap__logo {
  display: flex;
  align-items: center;
}
.wrapper__field-wrap .wrap__logo::after {
  content: "";
  display: inline-block;
  height: 24px;
  width: 1px;
  background-color: #e9ebed;
  margin: 0 10px;
}
.wrapper__field-wrap input {
  height: 24px;
  background: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.wrapper__field-wrap input:hover {
  background: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.wrapper__field-wrap input:focus {
  background: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.wrapper__field-wrap input:active {
  background: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
@media screen and (max-width: 512px) {
  .wrapper__input-code {
    margin: 0 -5px;
  }
  .wrapper__input-code .field__input {
    padding: 0 5px;
  }
}
.wrapper__modal-wrap {
  display: block;
  padding-right: 17px;
  visibility: hidden;
  transition: 0.5s;
  background-color: transparent !important;
}
.wrapper__modal-wrap .bg__wrap {
  background-color: #dbdff199;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}
.wrapper__modal-wrap .modal-dialog {
  z-index: 1059;
}
.wrapper__modal-wrap .modal-dialog .modal-content {
  padding: 40px 30px;
}
.wrapper__modal-wrap .modal-dialog .modal-content .close__wrap {
  width: 55px;
  height: 55px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -4rem;
  top: 0;
}
.wrapper__modal-wrap.show {
  visibility: visible;
}
.wrapper__text-wrap {
  padding: 2rem;
  border: 3px solid #8682e3;
  border-radius: 24px;
}
.wrapper__accordion-faq {
  border: 3px solid #e6dcfc;
  border-radius: 24px;
}
.wrapper__accordion-faq .head {
  padding: 1.5rem;
}
.wrapper__accordion-faq .head .arrow {
  width: 24px;
  height: 24px;
}
.wrapper__accordion-faq .head .arrow span {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 4px;
  border-radius: 50px;
  background-color: #8682e3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
}
.wrapper__accordion-faq .head .arrow span:last-child {
  transform: translate(-50%, -50%) rotate(90deg);
}
.wrapper__accordion-faq .line {
  height: 1px;
  background-color: #8682e3;
  margin: 0 1.5rem;
  transition: 0.5s;
  opacity: 0;
}
.wrapper__accordion-faq .desc {
  transition: 0.5s;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
}
.wrapper__accordion-faq.active {
  border: 3px solid #8682e3;
  box-shadow: 0px 13px 21px 0px #050b0e0d;
}
.wrapper__accordion-faq.active .head {
  padding: 1.5rem;
  padding-bottom: 1rem;
}
.wrapper__accordion-faq.active .head .arrow span {
  background-color: #626475;
  transition: 0.5s;
}
.wrapper__accordion-faq.active .head .arrow span:last-child {
  transform: translate(-50%, -50%) rotate(0deg);
}
.wrapper__accordion-faq.active .line {
  opacity: 1;
}
.wrapper__accordion-faq.active .desc {
  padding: 1.5rem;
  padding-top: 1rem;
  transition: 0.5s;
  max-height: 60vw;
  opacity: 1;
  overflow: visible;
}
.wrapper__category-faq {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -10px;
}
.wrapper__category-faq .items {
  margin: 0 10px;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 3px 19px 0px #8682e31a;
  transition: 0.5s;
}
.wrapper__category-faq .items:hover {
  color: white;
  background-color: #8682e3;
}
.wrapper__category-faq .items.active {
  color: white;
  background-color: #8682e3;
}
.wrapper__table-spec {
  box-shadow: 0px 13px 21px 0px #050b0e0d;
}
.wrapper__table-spec thead tr th {
  vertical-align: middle;
  background-color: #e6dcfc;
  border: 1px solid #8682e3;
  padding: 1.5rem;
}
.wrapper__table-spec thead tr th:first-child {
  border-top-left-radius: 24px;
}
.wrapper__table-spec thead tr th:last-child {
  border-top-right-radius: 24px;
}
.wrapper__table-spec tbody tr td,
.wrapper__table-spec tbody tr th {
  vertical-align: middle;
  padding: 1.5rem;
  border: 1px solid #8682e3;
}
.wrapper__table-spec tbody tr:last-child td:first-child,
.wrapper__table-spec tbody tr:last-child th:first-child {
  border-top-left-radius: 24px;
}
.wrapper__table-spec tbody tr:last-child td:last-child,
.wrapper__table-spec tbody tr:last-child th:last-child {
  border-top-right-radius: 24px;
}
.wrapper__grid-table .items {
  border: 1px solid #8682e3;
  height: 100%;
}
.wrapper__list-spec {
  padding: 1rem 3rem;
  border-radius: 16px;
  background-color: #8682e3;
}
@media screen and (max-width: 768px) {
  .wrapper__list-spec {
    padding: 2rem 2rem;
  }
}
.wrapper__card-user {
  padding: 2rem;
  border-radius: 32px;
  border: 3px solid #e6dcfc;
  width: 685px;
}
@media screen and (max-width: 768px) {
  .wrapper__card-user {
    width: 100%;
  }
}
.wrapper__card-user .user > img {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
}
.wrapper__slider-tetsimonials {
  margin: 0 -15px;
}
.wrapper__slider-tetsimonials .slick-dots {
  bottom: -3rem;
}
.wrapper__slider-tetsimonials .slick-dots li.slick-active button::before {
  background-color: #8682e3;
}
.wrapper__slider-tetsimonials .slick-dots li button::before {
  content: "";
  background-color: #e6dcfc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.wrapper__slider-tetsimonials .slick-current .items .wrapper__card-user {
  border: 3px solid #8682e3;
}
.wrapper__slider-tetsimonials .items {
  padding: 0 15px;
}
.wrapper__card-game .icon {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background: #8682e3;
  box-shadow: 0px 3px 26px rgba(134, 130, 227, 0.44);
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper__sosmed-list {
  position: absolute;
  right: 0;
  top: 0;
  list-style: none;
  padding: 0;
  margin: 0;
}
.wrapper__sosmed-list li {
  margin-bottom: 10px;
}
.wrapper__sosmed-list li:last-child {
  margin-bottom: 0;
}
.wrapper__side-line {
  position: relative;
  padding: 2rem;
  border-radius: 24px;
  background-color: #e4e4e7;
  overflow: hidden;
}
.wrapper__side-line::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 100%;
  background-color: #131c39;
}
.wrapper__card-news .img {
  width: 100%;
  height: 648px;
  object-fit: cover;
  border-radius: 32px;
}
@media screen and (max-width: 768px) {
  .wrapper__card-news .img {
    height: 270px;
  }
}
.wrapper__video-play {
  width: 100%;
  height: 326px;
  border-radius: 24px;
}
.wrapper__video-play .play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.wrapper__video-play .bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
}
.wrapper__video-play .cover {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.wrapper__profile-player div .user .img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}
.wrapper__profile-player div .user .win {
  position: absolute;
  width: 48px;
  left: 50%;
  transform: translateX(-50%);
  top: -2rem;
  object-fit: contain;
}
.wrapper__card-poin {
  padding: 2rem 3rem;
  background: rgba(255, 255, 255, 0.65);
  border: 3px solid #e6dcfc;
  border-radius: 32px;
  transition: 0.5s;
}
.wrapper__card-poin .row .number {
  transition: 0.5s;
  font-size: 28px;
}
.wrapper__card-poin .row .user div .win {
  position: absolute;
  width: 48px;
  left: 50%;
  transform: translateX(-50%);
  top: -3rem;
  object-fit: contain;
}
.wrapper__card-poin .row .user div img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
}
.wrapper__card-poin:hover {
  border: 3px solid #8682e3;
  box-shadow: 0px 13px 21px rgba(5, 11, 14, 0.05);
}
.wrapper__card-poin:hover .row .number {
  font-size: 40px;
}
.wrapper__star {
  display: flex;
  align-items: center;
  margin: 0 -2px;
}
@media screen and (max-width: 1024px) {
  .wrapper__star.small img {
    width: 28px;
  }
}
@media screen and (max-width: 375px) {
  .wrapper__star.small img {
    width: 24px;
  }
}
.wrapper__star img {
  margin: 0 2px;
}
.wrapper__charter-detail {
  display: flex;
}
.wrapper__charter-detail .img {
  width: 181.62px;
  height: 253px;
  object-fit: cover;
  border-radius: 32px;
  background-color: #c5ecf5;
}
@media screen and (max-width: 480px) {
  .wrapper__charter-detail .img {
    width: 122.76px;
    height: 171px;
  }
}
.wrapper__charter-detail .desc .user > img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}
.wrapper__charter-detail .desc .tag {
  margin: 0 -6px;
}
.wrapper__charter-detail .desc .tag .items {
  background-color: #e6dcfc;
  padding: 4px 10px;
  border-radius: 50px;
  margin: 0 6px;
}
.wrapper__characters-lits {
  padding-top: 3em;
  display: flex;
  justify-content: space-between;
  margin: 0 -8px;
}
.wrapper__characters-lits .items {
  padding: 0 8px;
}
.wrapper__images-icon {
  display: inline-block;
}
.wrapper__images-icon img {
  border: 2px solid white;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  transition: 0.5s;
}
.wrapper__images-icon .dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #8682e3;
  opacity: 0;
  transition: 0.5s;
}
.wrapper__images-icon.active img {
  width: 120px;
  height: 120px;
}
.wrapper__images-icon.active .dot {
  opacity: 1;
}
.wrapper__banner-gamgam {
  padding: 5rem 4rem;
  border-radius: 64px;
  background-color: #8682e3;
}
@media screen and (max-width: 768px) {
  .wrapper__banner-gamgam {
    padding: 3rem 2rem;
  }
}
@media screen and (max-width: 576px) {
  .wrapper__banner-gamgam {
    border-radius: 40px;
  }
}
.wrapper__card-blog .img {
  width: 100%;
  height: 270px;
  object-fit: cover;
  border-radius: 32px;
}
.wrapper__row-padding {
  margin: 0 -3rem;
}
.wrapper__row-padding .items {
  padding: 0 3rem;
}
.wrapper__card-map .img {
  width: 100%;
  height: 425px;
  object-fit: cover;
  border-radius: 32px;
}
@media screen and (max-width: 1024px) {
  .wrapper__card-map .img {
    height: 350px;
  }
}
.wrapper__card-map .img.left {
  transform: rotate(-3.78deg);
}
.wrapper__card-map .img.right {
  transform: rotate(1.99deg);
}
.wrapper__card-character {
  padding: 2rem 1rem;
}
.wrapper__card-character::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 32px;
  border: 3px solid #8682e3;
  filter: drop-shadow(0px 4px 6px #8682e3);
  transition: 0.5s;
  opacity: 0;
}
.wrapper__card-character .btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  padding: 12px 17px;
  z-index: 2;
  bottom: -4.5rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.5;
}
.wrapper__card-character .character {
  border-radius: 32px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: 0.5s;
}
.wrapper__card-character .character img {
  width: 100%;
  height: 375px;
  object-fit: cover;
  border-radius: 32px;
}
.wrapper__card-character:hover::after {
  opacity: 1;
}
.wrapper__card-character:hover .btn {
  bottom: -1.5rem;
  opacity: 1;
  visibility: visible;
}

.wrapper__cicle-user {
  margin-left: 20px;
}
.wrapper__cicle-user .items {
  margin-left: -20px;
}
.wrapper__cicle-user .items img {
  width: 67px;
  height: 67px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #f0f5ff;
}
@media screen and (max-width: 480px) {
  .wrapper__cicle-user .items img {
    width: 50px;
    height: 50px;
  }
}

.list__detail-news li {
  margin-bottom: 8px;
}
.list__detail-news li:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .clamp__md-3 {
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.position__initial {
  position: initial !important;
}
@media screen and (max-width: 768px) {
  .position__initial {
    position: relative !important;
  }
}

.images__maps {
  width: 100%;
  height: 100%;
  max-width: 428px;
  max-height: 548;
  border-radius: 32px;
}
.images__maps.left {
  transform: rotate(-6.17deg);
}
.images__maps.right {
  transform: rotate(5.46deg);
}
.images__desc-news {
  width: 100%;
  height: 423px;
  border-radius: 32px;
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .images__desc-news {
    height: 300px;
  }
}
.images__wrap-featured {
  width: 100%;
  height: 554px;
  border-radius: 32px;
  object-fit: contain;
}
@media screen and (max-width: 768px) {
  .images__wrap-featured {
    height: 313px;
  }
}
.images__character-side {
  max-width: 50vw;
}
@media screen and (max-width: 768px) {
  .images__character-side {
    position: initial;
    bottom: initial;
    left: initial;
    max-width: 100%;
    margin-bottom: -5rem;
  }
}
@media screen and (max-width: 480px) {
  .images__character-side {
    margin-bottom: -2rem;
  }
}
.images__banner-right {
  position: absolute;
  bottom: -13rem;
  right: -6rem;
}
@media screen and (max-width: 768px) {
  .images__banner-right {
    bottom: -9rem;
  }
}
@media screen and (max-width: 576px) {
  .images__banner-right {
    position: initial;
    bottom: initial;
    right: initial;
    width: 132vh;
    max-width: 158%;
    margin: 0 -8rem;
    margin-bottom: -12rem;
  }
}
@media screen and (max-width: 425px) {
  .images__banner-right {
    width: 132vh;
    max-width: 182%;
    margin: 0 -7rem;
    margin-bottom: -10rem;
  }
}
@media screen and (max-width: 375px) {
  .images__banner-right {
    width: 132vh;
    max-width: 186%;
    margin: 0 -7rem;
    margin-bottom: -9rem;
  }
}
.images__about-right {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  right: 0;
  width: 57vw;
}
@media screen and (max-width: 768px) {
  .images__about-right {
    position: initial;
    top: initial;
    transform: initial;
    left: initial;
    width: 100%;
    margin-bottom: -10rem;
    margin-left: -13rem;
    width: 121vw;
    max-width: 144%;
  }
}
@media screen and (max-width: 576px) {
  .images__about-right {
    margin-bottom: -7rem;
    margin-left: -9rem;
  }
}
@media screen and (max-width: 480px) {
  .images__about-right {
    margin-bottom: -5rem;
  }
}
@media screen and (max-width: 425px) {
  .images__about-right {
    margin-bottom: -4rem;
    margin-left: -7rem;
  }
}
@media screen and (max-width: 375px) {
  .images__about-right {
    margin-bottom: -3rem;
  }
}
.images__about-left {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  left: 0;
  width: 57vw;
}
@media screen and (max-width: 768px) {
  .images__about-left {
    position: initial;
    top: initial;
    transform: initial;
    left: initial;
    width: 100%;
    margin-bottom: -10rem;
    margin-left: -3rem;
    width: 121vw;
    max-width: 144%;
  }
}
@media screen and (max-width: 480px) {
  .images__about-left {
    margin-bottom: -7rem;
  }
}
@media screen and (max-width: 425px) {
  .images__about-left {
    margin-bottom: -6rem;
  }
}
@media screen and (max-width: 375px) {
  .images__about-left {
    margin-bottom: -5rem;
  }
}
.images__head-top {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.btn__sosmed-wrap {
  border-radius: 12px;
  width: 100%;
  padding: 12px 45px;
}
.btn__sosmed-wrap.fb {
  background-color: #465890;
}
.btn__sosmed-wrap.google {
  background-color: white;
  border: 1px solid #e5eaf1;
}
.btn__sosmed-wrap.google div span {
  color: #626475;
}
.btn__sosmed-wrap.apple {
  background-color: #000000;
}
.btn__sosmed-wrap.apple div span {
  color: white;
}

.list__normal {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list__normal li {
  margin-bottom: 8px;
}
.list__normal li:last-child {
  margin-bottom: 0;
}
.list__foooter-sosmed {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  margin: 0 -8px;
}
.list__foooter-sosmed li {
  margin: 0 8px;
}
.list__foooter-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list__foooter-menu li {
  margin-bottom: 8px;
}
.list__foooter-menu li:last-child {
  margin-bottom: 0;
}
.list__detail-price {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list__detail-price li {
  margin-bottom: 15px;
}
.list__detail-price li:last-child {
  margin-bottom: 0;
}

.line__hr {
  border-top: 1px solid #00000014;
  display: inline-block;
  width: 100%;
}

.pt__100 {
  padding-top: 100px;
}
@media screen and (max-width: 768px) {
  .pt__100 {
    padding-top: 61px;
  }
}

.p__4 {
  padding: 4rem;
}

@media screen and (max-width: 425px) {
  .px-xx-10 {
    padding: 0 10px;
  }
}

.mt__min-17 {
  margin-top: -17rem;
}
@media screen and (max-width: 480px) {
  .mt__min-17 {
    margin-top: -14rem;
  }
}
@media screen and (max-width: 375px) {
  .mt__min-17 {
    margin-top: -13rem;
  }
}
.mt__10 {
  margin-top: 10rem;
}
.mt__100 {
  margin-top: 100px;
}
.mt__5 {
  margin-top: 5rem;
}
@media screen and (max-width: 768px) {
  .mt__5.md-0 {
    margin-top: 0rem;
  }
}
.mt__10 {
  margin-top: 10rem;
}
@media screen and (max-width: 992px) {
  .mt__10.lg-0 {
    margin-top: 0rem;
  }
}
.mt__18 {
  margin-top: 18rem;
}
@media screen and (max-width: 768px) {
  .mt__18.md-0 {
    margin-top: 0rem;
  }
}

@media screen and (min-width: 768px) {
  .d__md-table-cell {
    display: table-cell !important;
  }
}

.hr__footer {
  border-top: 1px solid #322f37;
}
.hr__line-contact {
  border-top: 1px solid #d9d9d9;
}

.mb__min-3 {
  margin-bottom: -3rem;
}
@media screen and (min-width: 1771px) {
  .mb__min-3 {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .mb__min-3 {
    margin-bottom: 1rem;
  }
}
.mb__min-9 {
  margin-bottom: -9rem;
}
@media screen and (max-width: 576px) {
  .mb__min-9 {
    margin-bottom: 0;
  }
}
.mb__4 {
  margin-bottom: 4rem;
}
.mb__5 {
  margin-bottom: 5rem;
}
@media screen and (max-width: 576px) {
  .mb__5.sm-2 {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .mb__md-2 {
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .text__163-1024 {
    font-size: 100px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__163-md {
    font-size: 90px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__163-sm {
    font-size: 65px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__163-xs {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__100-1024 {
    font-size: 80px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__100-md {
    font-size: 70px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__100-sm {
    font-size: 60px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__100-mm {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__100-xx {
    font-size: 45px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__100-xxs {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__150-1024 {
    font-size: 120px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__150-md {
    font-size: 100px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__150-sm {
    font-size: 60px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__150-mm {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__150-xx {
    font-size: 45px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__150-xxs {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__200-md {
    font-size: 150px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__200-sm {
    font-size: 125px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__200-sm {
    font-size: 100px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__200-xx {
    font-size: 85px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__200-xxs {
    font-size: 75px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__77-sm {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__80-1024 {
    font-size: 55px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__80-md {
    font-size: 65px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__80-sm {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__80-xs {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__70-1024 {
    font-size: 60px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__70-md {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__70-sm {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__70-xs {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__70-xxs {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__65-1024 {
    font-size: 45px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__65-sm {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__65-xxs {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__60-1024 {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__60-sm {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__60-xxs {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__50-1024 {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__50-sm {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__50-xs {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__25-sm {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__45-1024 {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__45-md {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__45-mm {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__40-1024 {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__40-md {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__40-mm {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__30-1024 {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__30-md {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__30-sm {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__30-xx {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__32-1024 {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__32-xs {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__32-xxs {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__36-1024 {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__28-1024 {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__28-sm {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__35-1024 {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__35-md {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__35-xs {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__38-1024 {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__90-1024 {
    font-size: 70px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__90-md {
    font-size: 55px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__90-sm {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__90-xs {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__90-xxs {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__33-sm {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__33-xx {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__18-1024 {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__18-sm {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__18-xs {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__18-xxs {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__16-1024 {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__16-1024-12 {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__16-1024-10 {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__16-md {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__16-sm {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__16-mm {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__16-mm-12 {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__16-xx {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__20-1024 {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__20-md {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__20-sm {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__120-1024 {
    font-size: 90px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__120-md {
    font-size: 69px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__120-sm {
    font-size: 75px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__120-xxs {
    font-size: 55px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__26-md {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__26-sm {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__22-md {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__22-sm {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__12-md {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__12-sm {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__12-sm-8 {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__12-xs {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__12-xs-10 {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__12-mm {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__12-mm-10 {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__14-1024 {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__14-1024-10 {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__14-md {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__14-sm-8 {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__14-mm {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__14-mm-10 {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__14-xx {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__14-xx-8 {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__27-1024 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__24-1024 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__24-sm {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__24-xs {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__25-1024 {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__36-1024 {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__36-sm {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__58-sm {
    font-size: 45px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__48-1024 {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 768px) {
  .text__48-md {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__48-sm {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__48-xx {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__48-xxs {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 512px) {
  .w__mm-100 {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .wrap__flex-1024-100 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-100 > .grid {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-50 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-50 > .grid {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-90-3 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-90-3 > .grid {
    flex: 0 0 calc(90% / 3);
    max-width: calc(90% / 3);
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-auto {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-auto > .grid {
    flex: 0 0 auto !important;
    max-width: inherit !important;
  }
}
@media screen and (max-width: 768px) {
  .wrap__flex-md-100 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 768px) {
  .wrap__flex-md-100 > .grid {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .wrap__flex-md-50 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 768px) {
  .wrap__flex-md-50 > .grid {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 768px) {
  .wrap__flex-md-auto {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 768px) {
  .wrap__flex-md-auto > .grid {
    flex: 0 0 auto !important;
    max-width: inherit !important;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-sm-100 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-sm-100 > .grid {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-sm-50 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-sm-50 > .grid {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 480px) {
  .wrap__flex-xs-100 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 480px) {
  .wrap__flex-xs-100 > .grid {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-xs-50 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-xs-50 > .grid {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 512px) {
  .wrap__flex-mm-100 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 512px) {
  .wrap__flex-mm-100 > .grid {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-mm-50 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-mm-50 > .grid {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 512px) {
  .wrap__flex-mm-4 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 512px) {
  .wrap__flex-mm-4 > .grid {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }
}

@keyframes move1 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, 14.5rem);
  }
  75% {
    transform: translate(27rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move12 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(9.8rem, 13rem);
  }
  75% {
    transform: translate(19.7rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move2 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-27rem, 0rem);
  }
  75% {
    transform: translate(-13.4rem, 14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move22 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-19.5rem, 0rem);
  }
  75% {
    transform: translate(-10rem, 13rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move3 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, -14.5rem);
  }
  75% {
    transform: translate(-13.5rem, -14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move32 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(10rem, -13rem);
  }
  75% {
    transform: translate(-10rem, -13rem);
  }
  100% {
    transform: translate(0);
  }
}
.wrapper__dropdown .dropdown-menu {
  margin: 1.5rem 0 0;
  min-width: 100%;
  width: 332px;
  border: none;
  padding: 1rem 0 !important;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);
}
.wrapper__dropdown .dropdown-menu .hr__line {
  padding: 0 1rem !important;
}
.wrapper__dropdown .dropdown-menu .dropdown-item:hover {
  box-shadow: none !important;
  background-color: transparent !important;
  outline-style: none !important;
  color: #2e2f3a !important;
}
.wrapper__dropdown .dropdown-menu .dropdown-item:active {
  box-shadow: none !important;
  background-color: transparent !important;
  outline-style: none !important;
  color: #2e2f3a !important;
}
.wrapper__dropdown .dropdown-menu .dropdown-item:focus {
  box-shadow: none !important;
  background-color: transparent !important;
  outline-style: none !important;
  color: #2e2f3a !important;
}
.wrapper__dropdown .dropdown-menu .profile-user {
  padding: 0 1rem !important;
  display: inline-block;
  width: 100%;
}
.wrapper__side-menu .notification .circle {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  right: -5px;
  top: -5px;
}
.wrapper__side-menu .dropdown .dropdown-menu {
  padding: 0;
}
.wrapper__side-menu .dropdown .dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
}
.wrapper__side-nav-mobile {
  position: fixed;
  width: 90%;
  height: fit-content;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  z-index: 999;
  padding: 1.5rem;
  overflow: auto;
  transition: 0.5s;
}
.wrapper__side-nav-mobile div div .menu hr {
  border-top: 1px solid rgba(98, 100, 117, 0.1);
}
.wrapper__side-nav-mobile.active {
  top: 5%;
}
.wrapper__side-nav-mobile.active ~ .bg__wrap-menu {
  top: 0;
}
.wrapper__side-nav-mobile ~ .bg__wrap-menu {
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background: rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(4px);
  transition: 0.5s;
}
.wrapper__navbar {
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 997 !important;
}
.wrapper__navbar-menu .menu__center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
}
@media screen and (max-width: 768px) {
  .wrapper__navbar-menu .menu__center {
    position: initial;
    left: initial;
    top: initial;
    transform: initial;
    width: 100%;
  }
}
.wrapper__navbar-menu ul li {
  margin: 0 15px;
}
@media screen and (max-width: 1024px) {
  .wrapper__navbar-menu ul li {
    margin: 0 10px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper__navbar-menu ul li {
    margin: 0 0;
  }
}
.wrapper__navbar-menu ul li a {
  position: relative;
}
.wrapper__navbar-menu ul li a[aria-current="page"] {
  opacity: 1;
  color: !important;
}
.wrapper__navbar-menu ul li a[aria-current="page"]::after {
  opacity: 1;
}
.wrapper__navbar-menu ul li a:hover {
  color: !important;
}
.wrapper__navbar-menu ul li a:hover::after {
  opacity: 1;
}
.wrapper__navbar div .side {
  margin-right: -3rem;
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.wrapper__navbar div .side .sidebar {
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper__navbar div .side .sidebar .arrow {
  width: 30px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.wrapper__navbar div .side .sidebar .arrow span {
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  margin-bottom: 3px;
  display: inline-block;
  transition: 0.5s;
}
.wrapper__navbar div .side .sidebar .arrow span:nth-child(2) {
  width: 80%;
}
.wrapper__navbar div .side .sidebar .arrow span:nth-child(3) {
  width: 60%;
  margin-bottom: 0;
}

.nav__button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  outline-style: none;
  padding: 11px 9px;
}
.nav__button.light {
  background-color: #ffffff59;
}
.nav__button.light span {
  background-color: white !important;
}
.nav__button span {
  width: 100%;
  height: 2px;
  background-color: #2e2f3a;
  margin-bottom: 5px;
  display: inline-block;
  transition: 0.5s;
}
.nav__button span:nth-child(2) {
  width: 80%;
}
.nav__button span:nth-child(3) {
  width: 60%;
  margin-bottom: 0;
}
.nav__button:active {
  outline-style: none;
}
.nav__button:hover {
  outline-style: none;
}
.nav__button:focus {
  outline-style: none;
}
.nav__button[aria-expanded="true"] span:nth-child(1) {
  width: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  margin-bottom: 0;
}
.nav__button[aria-expanded="true"] span:nth-child(2) {
  width: 16px;
  display: none;
  margin-bottom: 0;
}
.nav__button[aria-expanded="true"] span:nth-child(3) {
  width: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.input__control {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  height: 68px;
}
.input__control:focus {
  box-shadow: none;
  border: 1px solid #e0e0e0;
}

