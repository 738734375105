@import "../abstracts/variables";
@import "../abstracts/functions";
@import "../abstracts/keyframes";

.section {
}

.field {
  &__input {
    border: 1px solid #e9ebed;
    background-color: #f9f9f9;
    padding: 1rem;
    display: flex;
    align-items: center;
    height: 60px;
    border-radius: 32px;

    &.min-250 {
      min-width: 250px;
    }

    &.w-sm-100 {
      @media screen and(max-width: $br-sm) {
        width: 100%;
        min-width: auto;
      }
    }

    &.search {
      width: 327px;
    }

    &.round__16 {
      border-radius: 16px;
    }

    &.verification {
      padding: 0;
      background-color: transparent;
      border: none;
      input {
        width: 50px;
        height: 50px;
        border-radius: 12px;
        box-shadow: none;
        background: #fefefe;
        border: 1px solid #e9ebed;
        border-radius: 24px;

        @media screen and(max-width: $br-mm) {
          width: 50px;
          height: 50px;
        }
        @media screen and(max-width: $br-xxs) {
          width: 43px;
          height: 43px;
        }

        &:valid {
          border: 1px solid #e9ebed;
          border-radius: 24px;
          box-shadow: none;
        }

        &:hover {
          border: 1px solid color("purple");
          border-radius: 24px;
          box-shadow: none;
        }
        &:focus {
          border: 1px solid color("purple");
          border-radius: 24px;
          box-shadow: none;
        }
        &:active {
          border: 1px solid color("purple");
          border-radius: 24px;
          box-shadow: none;
        }
      }
    }

    input {
      background-color: transparent;
      border: none;
      outline-style: none;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      width: 100%;

      &[type="date"]::-webkit-inner-spin-button,
      &[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }

      &:hover {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:focus {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:active {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
    }

    &.textarea {
      height: auto;
    }

    textarea {
      background-color: transparent;
      border: none;
      outline-style: none;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      width: 100%;

      &:hover {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:focus {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:active {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
    }
  }
}

.close__wrap {
  width: 55px;
  height: 55px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -4rem;
  top: 0;
  @media screen and(max-width: $br-md) {
    position: initial;
    margin-top: 3rem;
  }
}

.wrapper {
  &__content-offside {
    @media screen and(min-width: 1440px) {
      overflow: hidden;
      max-width: 1440px;
      margin:  0 auto;
    }
  }
  &__slider-team-wrap {
    margin: 0 -8px;

    .slick-dots {
      bottom: -3rem;
      li {
        &.slick-active {
          button {
            &::before {
              background-color: color("purple");
            }
          }
        }
        button {
          &::before {
            content: "";
            background-color: #e6dcfc;
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }
      }
    }

    .items {
      padding: 0 8px;
    }

    .slick-list {
      .slick-track {
        .slick-current {
          .items {
            .wrapper__team-user {
              &.left {
                transform: rotate(-2.29deg);
              }
              &.right {
                transform: rotate(2.29deg);
              }
            }
          }
        }
      }
    }
  }
  &__team-user {
    width: 100%;
    height: 440px;
    border-radius: 20px;
    overflow: hidden;
    transition: 0.5s;

    &.left {
      transform: rotate(-0.12deg);
    }
    &.right {
      transform: rotate(0.98deg);
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .bg {
      position: absolute;
      width: 100%;
      height: 220px;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }

    .desc {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 1rem;
    }
  }
  &__offside-faq {
    @media screen and(max-width: $br-sm) {
      overflow: auto;

      .auto {
        width: 700px;
      }
    }
  }
  &__offset-table {
    @media screen and(max-width: $br-md) {
      overflow: auto;

      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }

      .auto {
        width: 912px;
      }
    }
  }
  &__offsite-character {
    @media screen and(max-width: $br-sm) {
      overflow: auto;

      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }

      .auto {
        width: 692px;
      }
    }
  }
  &__modal-connect {
    display: block !important;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;

    &.post {
      .modal-dialog {
        max-width: 550px !important;
      }
    }

    .modal-content {
      padding: 2rem 1rem;

      .close__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #f9f9f9;
        border-radius: 50%;
        position: absolute;
        left: -20px;
        top: -20px;
      }
    }

    &.show {
      opacity: 1;
      visibility: visible;
      transition: 0.5s;
    }

    .content__modal-listing {
      .img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 16px;
        @media screen and(max-width: $br-xx) {
          width: 100px;
          height: 100px;
        }
      }

      .wrap {
        background: #f9f9f9;
        border-radius: 12px;
        padding: 8px 12px;
      }
    }

    // .modal-dialog {
    //   max-width: 350px !important;
    // }
  }
  &__checkbox-auth {
    input {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: -2;

      &:checked {
        ~ .circle {
          background-color: color("purple");
          .check {
            opacity: 1;
          }
        }
      }
    }

    .circle {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: white;
      position: relative;
      border: 1px solid #e5e5e7;

      .check {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.5s;
        opacity: 0;
      }
    }
  }
  &__line-text {
    .text {
      position: relative;
      z-index: 2;
      padding: 5px 24px;
      background-color: white;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #95a5b552;
    }
  }
  &__field-wrap {
    border: 1px solid #e9ebed;
    border-radius: 8px;
    padding: 16px;

    &.filter {
      background: #f9f9f9;
      border: 1px solid #e9ebed;
    }

    .wrap__logo {
      display: flex;
      align-items: center;
      &::after {
        content: "";
        display: inline-block;
        height: 24px;
        width: 1px;
        background-color: #e9ebed;
        margin: 0 10px;
      }
    }
    input {
      height: 24px;
      background: transparent;
      border: none;
      outline-style: none;
      box-shadow: none;
      &:hover {
        background: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:focus {
        background: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:active {
        background: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
    }
  }
  &__input-code {
    @media screen and(max-width: $br-mm) {
      margin: 0 -5px;

      .field__input {
        padding: 0 5px;
      }
    }
  }
  &__modal-wrap {
    display: block;
    padding-right: 17px;
    visibility: hidden;
    transition: 0.5s;
    background-color: transparent !important;

    .bg__wrap {
      background-color: #dbdff199;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
    }

    .modal-dialog {
      z-index: 1059;

      .modal-content {
        padding: 40px 30px;

        .close__wrap {
          width: 55px;
          height: 55px;
          background: rgba(255, 255, 255, 0.4);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -4rem;
          top: 0;

          // @media screen and(max-width: $br-md) {
          //   width: 35px;
          //   height: 35px;
          //   right: -10px;
          //   top: -10px;
          //   background-color: white;
          //   box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
          // }
        }
      }
    }
    &.show {
      visibility: visible;
    }
  }
  &__text-wrap {
    padding: 2rem;
    border: 3px solid #8682e3;
    border-radius: 24px;
  }
  &__accordion-faq {
    border: 3px solid #e6dcfc;
    border-radius: 24px;
    .head {
      padding: 1.5rem;

      .arrow {
        width: 24px;
        height: 24px;
        span {
          position: absolute;
          display: inline-block;
          width: 100%;
          height: 4px;
          border-radius: 50px;
          background-color: color("purple");
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: 0.5s;
          &:last-child {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
    }

    .line {
      height: 1px;
      background-color: #8682e3;
      margin: 0 1.5rem;
      transition: 0.5s;
      opacity: 0;
    }

    .desc {
      transition: 0.5s;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      padding: 0;
    }

    &.active {
      border: 3px solid color("purple");
      box-shadow: 0px 13px 21px 0px #050b0e0d;
      .head {
        padding: 1.5rem;
        padding-bottom: 1rem;

        .arrow {
          span {
            background-color: #626475;
            transition: 0.5s;
            &:last-child {
              transform: translate(-50%, -50%) rotate(0deg);
            }
          }
        }
      }

      .line {
        opacity: 1;
      }

      .desc {
        padding: 1.5rem;
        padding-top: 1rem;
        transition: 0.5s;
        max-height: 50vw;
        opacity: 1;
        overflow: visible;
      }
    }
  }
  &__category-faq {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -10px;

    .items {
      margin: 0 10px;
      padding: 1rem 1.5rem;
      border-radius: 12px;
      background-color: white;
      box-shadow: 0px 3px 19px 0px #8682e31a;
      transition: 0.5s;

      &:hover {
        color: white;
        background-color: color("purple");
      }
      &.active {
        color: white;
        background-color: color("purple");
      }
    }
  }
  &__table-spec {
    box-shadow: 0px 13px 21px 0px #050b0e0d;
    thead {
      tr {
        th {
          vertical-align: middle;
          background-color: #e6dcfc;
          border: 1px solid #8682e3;
          padding: 1.5rem;

          &:first-child {
            border-top-left-radius: 24px;
          }
          &:last-child {
            border-top-right-radius: 24px;
          }
        }
      }
    }
    tbody {
      tr {
        td,
        th {
          vertical-align: middle;
          padding: 1.5rem;
          border: 1px solid #8682e3;
        }

        &:last-child {
          td,
          th {
            &:first-child {
              border-top-left-radius: 24px;
            }
            &:last-child {
              border-top-right-radius: 24px;
            }
          }
        }
      }
    }
  }
  &__grid-table {
    .items {
      border: 1px solid #8682e3;
      height: 100%;
    }
  }
  &__list-spec {
    padding: 1rem 3rem;
    border-radius: 16px;
    background-color: color("purple");

    @media screen and(max-width: $br-md) {
      padding: 2rem 2rem;
    }
  }
  &__card-user {
    padding: 2rem;
    border-radius: 32px;
    border: 3px solid #e6dcfc;
    width: 685px;

    @media screen and(max-width: $br-md) {
      width: 100%;
    }

    .user {
      > img {
        width: 53px;
        height: 53px;
        border-radius: 50%;
        object-fit: cover;
        margin: 0 auto;
      }
    }
  }
  &__slider-tetsimonials {
    margin: 0 -15px;
    .slick-dots {
      bottom: -3rem;
      li {
        &.slick-active {
          button {
            &::before {
              background-color: color("purple");
            }
          }
        }
        button {
          &::before {
            content: "";
            background-color: #e6dcfc;
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }
      }
    }
    .slick-current {
      .items {
        .wrapper__card-user {
          border: 3px solid color("purple");
        }
      }
    }
    .items {
      padding: 0 15px;
    }
  }
  &__card-game {
    .icon {
      width: 53px;
      height: 53px;
      border-radius: 50%;
      background: #8682e3;
      box-shadow: 0px 3px 26px rgba(134, 130, 227, 0.44);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__sosmed-list {
    position: absolute;
    right: 0;
    top: 0;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__side-line {
    position: relative;
    padding: 2rem;
    border-radius: 24px;
    background-color: #e4e4e7;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 100%;
      background-color: #131c39;
    }
  }
  &__card-news {
    .img {
      width: 100%;
      height: 648px;
      object-fit: cover;
      border-radius: 32px;
      @media screen and(max-width: $br-md) {
        height: 270px;
      }
    }
  }
  &__video-play {
    width: 100%;
    height: 326px;
    border-radius: 24px;

    .play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    .bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 24px;
    }

    .cover {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  &__profile-player {
    div {
      .user {
        .img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          object-fit: cover;
        }

        .win {
          position: absolute;
          width: 48px;
          left: 50%;
          transform: translateX(-50%);
          top: -2rem;
          object-fit: contain;
        }
      }
    }
  }
  &__card-poin {
    padding: 2rem 3rem;
    background: rgba(255, 255, 255, 0.65);
    border: 3px solid #e6dcfc;
    border-radius: 32px;
    transition: 0.5s;
    .row {
      .number {
        transition: 0.5s;
        font-size: 28px;
      }
      .user {
        div {
          .win {
            position: absolute;
            width: 48px;
            left: 50%;
            transform: translateX(-50%);
            top: -3rem;
            object-fit: contain;
          }
          img {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }

    &:hover {
      border: 3px solid #8682e3;
      box-shadow: 0px 13px 21px rgba(5, 11, 14, 0.05);
      .row {
        .number {
          font-size: 40px;
        }
      }
    }
  }
  &__star {
    display: flex;
    align-items: center;
    margin: 0 -2px;

    &.small {
      img {
        @media screen and(max-width: 1024px) {
          width: 28px;
        }

        @media screen and(max-width: $br-xxs) {
          width: 24px;
        }
      }
    }

    img {
      margin: 0 2px;
    }
  }
  &__charter-detail {
    display: flex;
    .img {
      width: 181.62px;
      height: 253px;
      object-fit: cover;
      border-radius: 32px;
      background-color: #c5ecf5;

      @media screen and(max-width: $br-xs) {
        width: 122.76px;
        height: 171px;
      }
    }
    .desc {
      .user {
        > img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .tag {
        margin: 0 -6px;
        .items {
          background-color: #e6dcfc;
          padding: 4px 10px;
          border-radius: 50px;
          margin: 0 6px;
        }
      }
    }
  }
  &__characters-lits {
    display: flex;
    justify-content: space-between;
    margin: 0 -8px;
    .items {
      padding: 0 8px;
    }
  }
  &__images-icon {
    display: inline-block;
    img {
      border: 2px solid white;
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 50%;
      transition: 0.5s;
    }

    .dot {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: color("purple");
      opacity: 0;
      transition: 0.5s;
    }

    &.active {
      img {
        width: 120px;
        height: 120px;
      }
      .dot {
        opacity: 1;
      }
    }
  }
  &__banner-gamgam {
    padding: 5rem 4rem;
    border-radius: 64px;
    background-color: color("purple");
    @media screen and(max-width: $br-md) {
      padding: 3rem 2rem;
    }
    @media screen and(max-width: $br-sm) {
      border-radius: 40px;
    }
  }
  &__card-blog {
    .img {
      width: 100%;
      height: 270px;
      object-fit: cover;
      border-radius: 32px;
    }
  }
  &__row-padding {
    margin: 0 -3rem;
    .items {
      padding: 0 3rem;
    }
  }
  &__card-map {
    .img {
      width: 100%;
      height: 425px;
      object-fit: cover;
      border-radius: 32px;

      @media screen and(max-width: 1024px) {
        height: 350px;
      }

      &.left {
        transform: rotate(-3.78deg);
      }
      &.right {
        transform: rotate(1.99deg);
      }
    }
  }
  &__card-character {
    padding: 2rem 1rem;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 32px;
      border: 3px solid color("purple");
      filter: drop-shadow(0px 4px 6px color("purple"));
      transition: 0.5s;
      opacity: 0;
    }

    .btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: max-content;
      padding: 12px 17px;
      z-index: 2;
      bottom: -4.5rem;
      opacity: 0;
      visibility: hidden;
      transition: 0.5;
    }

    .character {
      border-radius: 32px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      transition: 0.5s;
      img {
        width: 100%;
        height: 375px;
        object-fit: cover;
        border-radius: 32px;
      }
    }

    &:hover {
      &::after {
        opacity: 1;
      }
      .btn {
        bottom: -1.5rem;
        opacity: 1;
        visibility: visible;
      }
      .character {
        background-color: transparent;
      }
    }
  }
  &__cicle-user {
    margin-left: 20px;
    .items {
      margin-left: -20px;
      img {
        width: 67px;
        height: 67px;
        border-radius: 50%;
        object-fit: cover;
        border: 3px solid #f0f5ff;

        @media screen and(max-width: $br-xs) {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
.list {
  &__detail-news {
    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.clamp {
  &__md-3 {
    @media screen and(max-width: $br-md) {
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.text {
}

.position {
  &__initial {
    position: initial !important;

    @media screen and(max-width: $br-md) {
      position: relative !important;
    }
  }
}

.images {
  &__maps {
    width: 100%;
    height: 100%;
    max-width: 428px;
    max-height: 548;
    border-radius: 32px;

    &.left {
      transform: rotate(-6.17deg);
    }
    &.right {
      transform: rotate(5.46deg);
    }
  }
  &__desc-news {
    width: 100%;
    height: 423px;
    border-radius: 32px;
    object-fit: cover;

    @media screen and(max-width: $br-md) {
      height: 300px;
    }
  }
  &__wrap-featured {
    width: 100%;
    height: 554px;
    border-radius: 32px;
    object-fit: cover;

    @media screen and(max-width: $br-md) {
      height: 313px;
    }
  }
  &__character-side {
    position: absolute;
    bottom: -10rem;
    left: 0rem;
    max-width: 50vw;

    @media screen and(max-width: $br-md) {
      position: initial;
      bottom: initial;
      left: initial;
      max-width: 100%;
      margin-bottom: -5rem;
    }
    @media screen and(max-width: $br-xs) {
      margin-bottom: -2rem;
    }
  }
  &__banner-right {
    position: absolute;
    bottom: -13rem;
    right: -6rem;

    @media screen and(max-width: $br-md) {
      bottom: -9rem;
    }
    @media screen and(max-width: $br-sm) {
      position: initial;
      bottom: initial;
      right: initial;
      width: 132vh;
      max-width: 158%;
      margin: 0 -8rem;
      margin-bottom: -12rem;
    }
    @media screen and(max-width: $br-xx) {
      width: 132vh;
      max-width: 182%;
      margin: 0 -7rem;
      margin-bottom: -10rem;
    }
    @media screen and(max-width: $br-xxs) {
      width: 132vh;
      max-width: 186%;
      margin: 0 -7rem;
      margin-bottom: -9rem;
    }
  }
  &__about-right {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: 0;
    width: 57vw;

    @media screen and(max-width: $br-md) {
      position: initial;
      top: initial;
      transform: initial;
      left: initial;
      width: 100%;
      margin-bottom: -10rem;
      margin-left: -13rem;
      width: 121vw;
      max-width: 144%;
    }
    @media screen and(max-width: $br-sm) {
      margin-bottom: -7rem;
      margin-left: -9rem;
    }
    @media screen and(max-width: $br-xs) {
      margin-bottom: -5rem;
    }
    @media screen and(max-width: $br-xx) {
      margin-bottom: -4rem;
      margin-left: -7rem;
    }
    @media screen and(max-width: $br-xxs) {
      margin-bottom: -3rem;
    }
  }
  &__about-left {
    position: absolute;
    top: 70%;
    transform: translateY(-50%);
    left: 0;
    width: 57vw;

    @media screen and(max-width: $br-md) {
      position: initial;
      top: initial;
      transform: initial;
      left: initial;
      width: 100%;
      margin-bottom: -10rem;
      margin-left: -3rem;
      width: 121vw;
      max-width: 144%;
    }
    @media screen and(max-width: $br-xs) {
      margin-bottom: -7rem;
    }
    @media screen and(max-width: $br-xx) {
      margin-bottom: -6rem;
    }
    @media screen and(max-width: $br-xxs) {
      margin-bottom: -5rem;
    }
  }
  &__head-top {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}

.btn {
  &__sosmed-wrap {
    border-radius: 12px;
    width: 100%;
    padding: 12px 45px;

    &.fb {
      background-color: #465890;
    }

    &.google {
      background-color: white;
      border: 1px solid #e5eaf1;

      div {
        span {
          color: color("gray-1");
        }
      }
    }

    &.apple {
      background-color: #000000;
      div {
        span {
          color: white;
        }
      }
    }
  }
}

.path {
}

.list {
  &__normal {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__foooter-sosmed {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    margin: 0 -8px;

    li {
      margin: 0 8px;
    }
  }
  &__foooter-menu {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__detail-price {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.line {
  &__hr {
    border-top: 1px solid #00000014;
    display: inline-block;
    width: 100%;
  }
}

.pt {
  &__100 {
    padding-top: 100px;
    @media screen and(max-width: $br-md) {
      padding-top: 61px;
    }
  }
}

.p {
  &__4 {
    padding: 4rem;
  }
}

.px {
  &-xx-10 {
    @media screen and(max-width: $br-xx) {
      padding: 0 10px;
    }
  }
}

.mt {
  &__min {
    &-17 {
      margin-top: -17rem;

      @media screen and(max-width: $br-xs) {
        margin-top: -14rem;
      }
      @media screen and(max-width: $br-xxs) {
        margin-top: -13rem;
      }
    }
  }
  &__10 {
    margin-top: 10rem;
  }
  &__100 {
    margin-top: 100px;
  }
  &__5 {
    margin-top: 5rem;

    &.md-0 {
      @media screen and(max-width: $br-md) {
        margin-top: 0rem;
      }
    }
  }
  &__10 {
    margin-top: 10rem;

    &.lg-0 {
      @media screen and(max-width: $br-lg) {
        margin-top: 0rem;
      }
    }
  }
  &__18 {
    margin-top: 18rem;

    &.md-0 {
      @media screen and(max-width: $br-md) {
        margin-top: 0rem;
      }
    }
  }
}

.d {
  &__md {
    &-table-cell {
      @media screen and(min-width:  $br-md) {
        display: table-cell !important;
      }
    }
  }
}

.hr {
  &__footer {
    border-top: 1px solid #322f37;
  }
  &__line-contact {
    border-top: 1px solid #d9d9d9;
  }
}

.mb {
  &__min {
    &-3 {
      margin-bottom: -3rem;
      @media screen and(min-width:  1771px) {
        margin-bottom: 0;
      }
      @media screen and(max-width:  $br-md) {
        margin-bottom: 1rem;
      }
    }
  }
  &__min-9 {
    margin-bottom: -9rem;

    @media screen and(max-width:  $br-sm) {
      margin-bottom: 0;
    }
  }
  &__4 {
    margin-bottom: 4rem;
  }
  &__5 {
    margin-bottom: 5rem;

    &.sm-2 {
      @media screen and(max-width:  $br-sm) {
        margin-bottom: 1rem;
      }
    }
  }

  &__md-2 {
    @media screen and(max-width: $br-md) {
      margin-bottom: 1.5rem;
    }
  }
}

// typography

.text {
  &__163 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 100px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 90px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 65px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 50px !important;
      }
    }
  }

  &__100 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 80px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 70px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }
  &__150 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 120px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 100px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }

  &__200 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 150px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 125px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-mm) {
        font-size: 100px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 85px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 75px !important;
      }
    }
  }

  &__77 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }
  }

  &__80 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 55px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 65px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }
  }

  &__70 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 60px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 40px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 35px !important;
      }
    }
  }

  &__65 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 45px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 28px !important;
      }
    }
  }

  &__60 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 30px !important;
      }
    }
  }

  &__50 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 25px !important;
      }
    }
  }

  &__25 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__45 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 24px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 16px !important;
      }
    }
  }

  &__40 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 28px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 24px !important;
      }
    }
  }

  &__30 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 20px !important;
      }
    }
  }

  &__32 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 24px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 16px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }
  }

  &__28 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__35 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
  }

  &__38 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }
  }

  &__90 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 70px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 55px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 40px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xs) {
        font-size: 30px !important;
      }
    }
  }

  &__33 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 18px !important;
      }
    }
  }

  &__18 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 12px !important;
      }
    }
  }

  &__16 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 14px !important;
      }

      &-12 {
        @media screen and(max-width: 1024px) {
          font-size: 12px !important;
        }
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 10px !important;
      }

      &-12 {
        @media screen and(max-width: $br-mm) {
          font-size: 12px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }
    }
  }

  &__20 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 16px !important;
      }
    }
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 14px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }
  }

  &__120 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 90px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 69px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 75px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 55px !important;
      }
    }
  }

  &__26 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__22 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__12 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 10px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-xs) {
          font-size: 10px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }
  }

  &__14 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 12px !important;
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 9px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-xx) {
          font-size: 8px !important;
        }
      }
    }
  }

  &__27 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }
  }

  &__24 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }
  }

  &__25 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__58 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 45px !important;
      }
    }
  }

  &__48 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 35px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 30px !important;
      }
    }
    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 24px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 18px !important;
      }
    }
  }
}

.w {
  &__mm-100 {
    @media screen and(max-width: $br-mm) {
      width: 100%;
    }
  }
}

.wrap {
  &__flex {
    &-1024 {
      &-100 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 100% !important;
            max-width: 100% !important;
          }
        }
      }

      &-50 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-90 {
        &-3 {
          @media screen and(max-width: 1024px) {
            flex-flow: wrap;
          }
          > .grid {
            @media screen and(max-width: 1024px) {
              flex: 0 0 calc(90% / 3);
              max-width: calc(90% / 3);
            }
          }
        }
      }

      &-auto {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-md {
      &-100 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-auto {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-sm {
      &-100 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-xs {
      &-100 {
        @media screen and(max-width: $br-xs) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-xs) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-mm {
      &-100 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-4 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 calc(100% / 6);
            max-width: calc(100% / 6);
          }
        }
      }
    }
  }
}

// animated
@keyframes move1 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, 14.5rem);
  }
  75% {
    transform: translate(27rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move12 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(9.8rem, 13rem);
  }
  75% {
    transform: translate(19.7rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move2 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-27rem, 0rem);
  }
  75% {
    transform: translate(-13.4rem, 14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move22 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-19.5rem, 0rem);
  }
  75% {
    transform: translate(-10rem, 13rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move3 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, -14.5rem);
  }
  75% {
    transform: translate(-13.5rem, -14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move32 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(10rem, -13rem);
  }
  75% {
    transform: translate(-10rem, -13rem);
  }
  100% {
    transform: translate(0);
  }
}
